import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WINDOW } from "./windows.service";

// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})
export class NavService {

	public screenWidth: any;
	public collapseSidebar: boolean = false;
	// Observable menu items
	items = new BehaviorSubject<Menu[]>([]);

	constructor(@Inject(WINDOW) private window) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true;
		}
		// Load menu items directly (hardcoded menu)
		this.loadMenu();
	}

	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	private loadMenu() {
		const hardcodedMenu: Menu[] = [
			{
				title: 'Tickets',
				path: '/dashboard/default',
				icon: 'home',
				type: 'sub',
				badgeType: 'primary',
				active: false,
				children: [
					{ title: 'Lista Tickets', path: '/tickets/backlog-tickets', type: 'link', active: true },
					{ title: 'Tablero', path: '/tickets/tablero', type: 'link', active: true }
				]
			},
			{
				title: 'Ticket Usuario',
				icon: 'home',
				type: 'sub',
				badgeType: 'primary',
				active: false,
				children: [
					{ title: 'Crear Tickets', path: '/tickets/crearTicketsUsuarioExterno', type: 'link', active: true }
				]
			}
			// ,
			// {
			// 	title: 'Maestros',
			// 	icon: 'home',
			// 	type: 'sub',
			// 	active: false,
			// 	children: [
			// 		{ title: 'Maestro de Usuarios', path: '/Maestros/maestro-usuarios', type: 'link', badgeType: 'primary', active: true },
			// 		{ title: 'Maestro de Categorias', path: '/Maestros/maestro-categorias', type: 'link', badgeType: 'primary', active: true }
			// 	]
			// }
		];
		this.items.next(hardcodedMenu); // Update BehaviorSubject with hardcoded menu
	}
}