<div class="login-page vh-100">
    <!-- Imagen de fondo -->
    <div class="background-image">
        <img src="../../../../assets/images/logo-desktop.png" alt="Fondo" class="img-fluid w-100 h-100">
    </div>

    <!-- Formulario de ingreso -->
    <div class="form-container position-absolute end-0 top-0 vh-100 d-flex align-items-center">
        <div class="login-form-container text-center p-4 mx-auto">
            <h2 class="mb-4 font-color-welcome">Bienvenido a Soporte de Katuq</h2>
            <form [formGroup]="loginForm">
                <div class="form-group">
                    <label for="email"><strong>Usuario</strong></label>
                    <input type="text" class="form-control custom-input" id="email" placeholder="Correo electrónico" formControlName="email" required>
                    <div *ngIf="loginForm.get('email').invalid && loginForm.get('email').touched">
                        <small class="text-danger" *ngIf="loginForm.get('email').errors?.required">El usuario es requerido.</small>
                        <small class="text-danger" *ngIf="loginForm.get('email').errors?.email">Ingresa un usuario válido.</small>
                    </div>
                </div>
                <div class="form-group mt-4">
                    <label for="password"><strong>Contraseña</strong></label>
                    <input type="password" class="form-control custom-input" id="password" placeholder="Ingresa tu contraseña" formControlName="pwd" required>
                </div>
                <div class="button-container mt-4">
                    <button class="btn btn-solid custom-btn" type="submit" [disabled]="loginForm.invalid" (click)="onLogin()">Ingresar</button>
                </div>
            </form>
        </div>
    </div>
</div>
