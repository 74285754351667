import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class CoreService {
  protected http: HttpClient;
  // private urlBase = environment.urlPuntosLeonisa;
 

  constructor(http: HttpClient) {
    this.http = http;
  }



}
